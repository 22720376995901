import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, onValue, serverTimestamp, set, get, orderByChild } from "firebase/database";
import { query } from "firebase/firestore";

import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD9DMj2De6CZhtMaqtbAX-E1wXcn1TxT40",
  authDomain: "astros-india-1f91c.firebaseapp.com",
  databaseURL: "https://astros-india-1f91c-default-rtdb.firebaseio.com",
  projectId: "astros-india-1f91c",
  storageBucket: "astros-india-1f91c.appspot.com",
  messagingSenderId: "448602138951",
  appId: "1:448602138951:web:fa4375ce049dad3726c462",
  measurementId: "G-V0JVK346DJ"
};

//! Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

//! Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export { database, ref, push, onValue, serverTimestamp, set, messaging, get, orderByChild, query };